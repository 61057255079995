var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"uploader-wrapper"},[_c('div',{staticClass:"small-img-wrapper"},[_c('image-uploader',{ref:"uploader1",staticClass:"logo-uploader",attrs:{"indexToInject":0,"info":{},"fileTypes":"image/jpg, image/jpeg, image/png, image/gif"},on:{"data":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.sendImageUpload.apply(void 0, [ 0 ].concat( argsArray ))},"error":_vm.sendErrors}}),_vm._m(0),_c('image-uploader',{ref:"uploader2",staticClass:"logo-uploader",attrs:{"indexToInject":1,"info":{},"fileTypes":"image/jpg, image/jpeg, image/png, image/gif"},on:{"data":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.sendImageUpload.apply(void 0, [ 1 ].concat( argsArray ))},"error":_vm.sendErrors}}),_vm._m(1),_c('image-uploader',{ref:"uploader3",staticClass:"logo-uploader",attrs:{"indexToInject":2,"info":{},"fileTypes":"image/jpg, image/jpeg, image/png, image/gif"},on:{"data":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.sendImageUpload.apply(void 0, [ 2 ].concat( argsArray ))},"error":_vm.sendErrors}}),_vm._m(2)],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"small-image-instructions"},[_c('label',[_vm._v("Max Size 10 MB (JPG, JPEG, PNG or GIF only)")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"small-image-instructions"},[_c('label',[_vm._v("Max Size 10 MB (JPG, JPEG, PNG or GIF only)")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"small-image-instructions"},[_c('label',[_vm._v("Max Size 10 MB (JPG, JPEG, PNG or GIF only)")])])}]

export { render, staticRenderFns }