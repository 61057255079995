<template>
  <div class="uploader-wrapper">
    <div class="small-img-wrapper">
      <image-uploader
        ref="uploader1"
        :indexToInject="0"
        :info="{}"
        fileTypes="image/jpg, image/jpeg, image/png, image/gif"
        v-on:data="sendImageUpload(0, ...arguments)"
        v-on:error="sendErrors"
        class="logo-uploader"
      />
      <div class="small-image-instructions">
        <label>Max Size 10 MB (JPG, JPEG, PNG or GIF only)</label>
      </div>
      <image-uploader
        ref="uploader2"
        :indexToInject="1"
        :info="{}"
        fileTypes="image/jpg, image/jpeg, image/png, image/gif"
        v-on:data="sendImageUpload(1, ...arguments)"
        v-on:error="sendErrors"
        class="logo-uploader"
      />
      <div class="small-image-instructions">
        <label>Max Size 10 MB (JPG, JPEG, PNG or GIF only)</label>
      </div>
      <image-uploader
        ref="uploader3"
        :indexToInject="2"
        :info="{}"
        fileTypes="image/jpg, image/jpeg, image/png, image/gif"
        v-on:data="sendImageUpload(2, ...arguments)"
        v-on:error="sendErrors"
        class="logo-uploader"
      />
      <div class="small-image-instructions">
        <label>Max Size 10 MB (JPG, JPEG, PNG or GIF only)</label>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
.uploader-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  height: 250px;

  @media screen and (max-width: 695px) {
    height: 350px;
  }

  @media screen and (max-width: 400px) {
    height: 400px;
  }

  .small-img-wrapper {
    display: flex;
    // flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 125px;

    .small-image-instructions {
      display: flex;
      justify-content: center;
      margin-top: 3px;
      margin-bottom: 1em;

      label {
        color: #5b5b5b;
        font-size: 12px;
      }
    }

    @media screen and (max-width: 695px) {
      height: 275px;
    }

    @media screen and (max-width: 400px) {
      height: 425px;
    }

    .small-img {
      height: 50px;
      border: #101216 solid 1px;
      background-color: rgb(29, 29, 29);
      transition: 500ms;
      border-radius: 4px;

      @media screen and (max-width: 695px) {
        width: 30%;
      }

      @media screen and (max-width: 400px) {
        width: 47%;
      }

      &:hover {
        border: rgba(91, 91, 91, 0.5) dashed 2px;
      }

      .file-upload-wrapper {
        display: flex;
        position: relative;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 40px;

        .small-image {
          position: relative;
          display: none;
          width: 100%;
          height: 100%;
        }
        // end small-image

        .file-upload-container {
          position: absolute;
          display: flex;
          justify-content: center;
          width: 100%;
          cursor: pointer;

          .image-border-wrapper {
            border: #807b7b dashed 2px;
            width: 40px;
            height: 35px;
            margin-left: 5px;
            text-align: center;
            color: #5b5b5b;

            .image-element {
              width: 100%;
              height: 100%;
            }
            //end image element

            &::first-line {
              line-height: 35px;
            }
          }

          .upload-icon {
            margin-right: 7px;
            color: #4eb95e;
          }

          .img-upload {
            display: none;
          }
          // end img-upload

          label {
            overflow: hidden;
          }

          .img-upload + label {
            display: flex;
            justify-content: center;
            color: #5b5b5b;
            align-items: center;
            font-size: 12px;
            width: 20%;
            height: 100%;
            cursor: pointer;
          }
          // end img-upload + label

          .img-icon {
            position: relative;
            left: 15px;
            justify-content: center;
            color: #5b5b5b;
            cursor: pointer;
          }
          // end img-icon
        }
        // end file-upload-container
      }
      // end file-upload-wrapper
    }
    // end small-img
  }
  // end small-img-wrapper

  .submission-wrapper {
    display: flex;
    justify-content: center;
    width: 100%;

    .button-wrapper {
      width: 100px;
      margin: auto;
    }
    // end button-wrapper
  }
  // end submission-wrapper

  // @media screen and (max-width: 695px) {
  //     .uploader-wrapper{
  //         height: 750px;

  //         .small-img-wrapper{
  //             height: 50px;
  //         }
  //     }
  // }
}
</style>

<script>
import ImageUploader from "./imageUploaders/TTButtonImageUploader.vue";
import DiyoboInput from "@/components/DiyoboInput.vue";
import DiyoboButton from "@/components/DiyoboButton.vue";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faImages,
  faPlusSquare,
  faUpload
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(faImages, faPlusSquare, faUpload);

export default {
  name: "send-mail-image-uploader",
  components: {
    DiyoboInput,
    DiyoboButton,
    FontAwesomeIcon,
    ImageUploader
  },
  props: {
    txt: String,
    type: String
  },
  data() {
    return {
      attachments: [
        { image: "", imageName: "" },
        { image: "", imageName: "" },
        { image: "", imageName: "" }
      ]
    };
  },
  methods: {
    sendImageUpload(index, imageObj) {
      console.log(imageObj, "obj we got hhahahahahhaha");
      this.$emit("input", index, imageObj.imageFile);
    },
    sendErrors(errorMsg) {
      this.$emit("error", errorMsg);
    },
    reset() {
      this.$refs.uploader1.deleteButton();
      this.$refs.uploader2.deleteButton();
      this.$refs.uploader3.deleteButton();

      this.attachments = [
        { image: "", imageName: "" },
        { image: "", imageName: "" },
        { image: "", imageName: "" }
      ];
    }
  }
};
</script>
